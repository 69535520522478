import React from 'react'
import { connect } from 'react-redux'
import autobind from 'autobind-decorator'
import classnames from 'classnames'
import $ from 'jquery'
import forEach from 'lodash/forEach'
import debounce from 'lodash/debounce'

import * as actions from '../actions'

@connect(state => ({
  neighborhoods: state.neighborhood.groupedNeighborhoods,
  selectedNeighborhoods: state.neighborhood.selectedNeighborhoods,
}), actions)
@autobind
export default class NeighborhoodFilter extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showNeighborhoodPanel: false,
      neighborhoodQuery: '',
      activeSublocality: props.neighborhoods[0],
    }
  }

  componentDidMount() {
    // if (this.props.closeOnClickOutside) {
    // $(document).mousedown((e) => {
    //   if (this.state.showNeighborhoodPanel) {
    //     const container = $('.neighbourhood-searchbar-panel')
    //     const container2 = $('.neighbourhood-searchbar-input')

    //     // if the target of the click isn't the container nor a descendant of the container
    //     if (!container.is(e.target) && container.has(e.target).length === 0 && !container2.is(e.target) && container2.has(e.target).length === 0) {
    //       this.submit()
    //     }
    //   }
    // })
  }

  componentWillUnmount() {
    // $(document).off('mousedown')
  }

  submit() {
    const {
      updateMethod,
      selectedNeighborhoods,
    } = this.props

    const {
      neighborhoodQuery,
    } = this.state

    this.closeNeighborhoodPanel()

    if (!selectedNeighborhoods.length && neighborhoodQuery) {
      this.submitSearchAddress()
    } else if (typeof updateMethod === "function") {
      this.setState({
        neighborhoodQuery: '',
      })

      updateMethod(selectedNeighborhoods)
    }
  }

  submitSearchAddress() {
    const {
      onSubmitAddress,
    } = this.props

    const {
      neighborhoodQuery,
    } = this.state

    this.closeNeighborhoodPanel()

    if (typeof onSubmitAddress === "function") {
      onSubmitAddress(neighborhoodQuery)
    }
  }

  closeNeighborhoodPanel() {
    $('html, body').removeClass('prevent-scroll')

    this.setState({
      showNeighborhoodPanel: false,
      neighborhoodQuery: '',
    }, () => {
      if (typeof this.props.onPanelClose === "function") {
        this.props.onPanelClose()
      }
    })
  }

  setActiveSublocality(name) {
    const {
      neighborhoods,
    } = this.props

    this.setState({
      activeSublocality: neighborhoods.find(n => n.sublocality === name),
    })
  }

  bindPanelScroll() {
    let groups = [],
      scroll

    const self = this

    const doTheScroll = () => {
      groups = {
        Manhattan: document.querySelectorAll(`.region-wrapper[sublocalityname=Manhattan]`)[0].offsetTop,
        Brooklyn: document.querySelectorAll(`.region-wrapper[sublocalityname=Brooklyn]`)[0].offsetTop,
        Queens: document.querySelectorAll(`.region-wrapper[sublocalityname=Queens]`)[0].offsetTop,
      }
      scroll = document.getElementsByClassName('neighbourhood-options')[0].scrollTop

      if (scroll >= groups['Brooklyn'] - 20 && scroll < groups['Queens'] - 20) {
        self.setActiveSublocality('Brooklyn')
      } else if (scroll >= groups['Queens'] - 20) {
        self.setActiveSublocality('Queens')
      } else {
        self.setActiveSublocality('Manhattan')
      }
    }

    $('.neighbourhood-options').scroll(debounce(doTheScroll, 50))
  }

  openNeighborhoodPanel() {
    this.neighborhoodQueryInput && this.neighborhoodQueryInput.focus()

    if ($(window).width() < 767) {
      $('html, body').animate({
        scrollTop: ($('.neighbourhood-searchbar').offset().top - 20) + 'px'
      })
    }

    $('html, body').addClass('prevent-scroll')

    this.setState({
      showNeighborhoodPanel: true,
    }, () => {
      if (typeof this.props.onPanelOpen === 'function') {
        this.props.onPanelOpen()
      }

      this.bindPanelScroll()
    })
  }

  clearSearchbarInput() {
    this.setState({
      neighborhoodQuery: '',
    }, () => {
      this.neighborhoodQueryInput && this.neighborhoodQueryInput.focus()
    })
  }

  getGroupedSelectedNeighborhoods() {
    const {
      neighborhoods,
      selectedNeighborhoods,
    } = this.props

    let selection = []
    let regionSelection = []
    let neighborhoodSelection = []

    forEach(neighborhoods, sublocality => {
      regionSelection = []

      forEach(sublocality.regions, region => {
        neighborhoodSelection = []

        forEach(region.neighbourhoods, neighborhood => {
          if (selectedNeighborhoods.indexOf(neighborhood.name) !== -1) {
            neighborhoodSelection.push(neighborhood.name)
          }
        })

        if (neighborhoodSelection.length === region.neighbourhoods.length) {
          regionSelection.push(region.name)
        } else {
          selection = selection.concat(neighborhoodSelection)
        }
      })

      if (regionSelection.length === sublocality.regions.length) {
        selection.push(sublocality.sublocality)
      } else {
        selection = selection.concat(regionSelection)
      }
    })

    return selection
  }

  removeFromSelection(neighborhoodName) {
    const {
      neighborhoods,
      setSelectedNeighborhoods,
      selectedNeighborhoods,
    } = this.props

    let removeWholeSublocality,
      removeWholeRegion,
      removeIndex

    let newSelectedNeighborhoods = selectedNeighborhoods

    forEach(neighborhoods, sublocality => {
      removeWholeSublocality = false

      if (sublocality.sublocality === neighborhoodName) {
        removeWholeSublocality = true
      }

      forEach(sublocality.regions, region => {
        removeWholeRegion = false

        if (region.name === neighborhoodName) {
          removeWholeRegion = true
        }

        forEach(region.neighbourhoods, neighborhood => {
          if (removeWholeSublocality || removeWholeRegion || neighborhood.name === neighborhoodName) {
            removeIndex = selectedNeighborhoods.indexOf(neighborhood.name)

            if (removeIndex !== -1) {
              newSelectedNeighborhoods.splice(removeIndex, 1)
              setSelectedNeighborhoods(newSelectedNeighborhoods)
            }
          }
        })
      })
    })
  }

  isSublocalityFiltered(sublocality) {
    const {
      neighborhoodQuery,
    } = this.state

    if (neighborhoodQuery) {
      const query = neighborhoodQuery.toLowerCase()

      const foundRegion = sublocality.regions.find(region => {
        let foundNeighborhood = region.neighbourhoods.find(neighborhood => {
          return neighborhood.name.toLowerCase().indexOf(query) !== -1
        })

        return foundNeighborhood || region.name.toLowerCase().indexOf(query) !== -1
      })

      return foundRegion || sublocality.sublocality.toLowerCase().indexOf(query) !== -1
    }

    return true
  }

  isRegionFiltered(region) {
    const {
      neighborhoodQuery,
    } = this.state

    if (neighborhoodQuery) {
      const query = neighborhoodQuery.toLowerCase()

      const foundNeighborhood = region.neighbourhoods.find(neighborhood => {
        return neighborhood.name.toLowerCase().indexOf(query) !== -1
      })

      return foundNeighborhood || region.name.toLowerCase().indexOf(query) !== -1
    }

    return true
  }

  isNeighborhoodFiltered(neighborhoodName) {
    const {
      neighborhoodQuery,
    } = this.state

    return neighborhoodQuery ? neighborhoodName.toLowerCase().indexOf(neighborhoodQuery.toLowerCase()) !== -1 : true
  }

  isSublocalitySelected(sublocality) {
    for (let i = 0; i < sublocality.regions.length; ++ i) {
      if (!this.isRegionSelected(sublocality.regions[i])) {
        return false
      }
    }
    return true
  }

  toggleWholeSublocality(sublocality) {
    if (this.isSublocalitySelected(sublocality)) {
      // deselect all regions
      for (let i = 0; i < sublocality.regions.length; ++ i) {
        this.deselectRegion(sublocality.regions[i])
      }
    } else {
      // select all regions
      for (let i = 0; i < sublocality.regions.length; ++ i) {
        this.selectRegion(sublocality.regions[i])
      }
      this.clearSearchbarInput()
    }
  }

  selectRegion(region) {
    const {
      selectedNeighborhoods,
      setSelectedNeighborhoods,
    } = this.props

    let newSelectedNeighborhoods = selectedNeighborhoods

    for (let i = 0; i < region.neighbourhoods.length; ++ i) {
      let neighborhoodIndex = selectedNeighborhoods.indexOf(region.neighbourhoods[i].name)
      if (neighborhoodIndex === -1) {
        newSelectedNeighborhoods.unshift(region.neighbourhoods[i].name)
        setSelectedNeighborhoods(newSelectedNeighborhoods)
      }
    }
  }

  deselectRegion(region) {
    const {
      selectedNeighborhoods,
      setSelectedNeighborhoods,
    } = this.props

    let newSelectedNeighborhoods = selectedNeighborhoods

    for (let i = 0; i < region.neighbourhoods.length; ++ i) {
      let neighborhoodIndex = selectedNeighborhoods.indexOf(region.neighbourhoods[i].name)

      if (neighborhoodIndex > -1) {
        newSelectedNeighborhoods.splice(neighborhoodIndex, 1)
        setSelectedNeighborhoods(newSelectedNeighborhoods)
      }
    }
  }

  toggleWholeRegion(region) {
    if (this.isRegionSelected(region)) {
      // deselect all neighborhoods
      this.deselectRegion(region)
    } else {
      // select all neighborhoods
      this.selectRegion(region)
      this.clearSearchbarInput()
    }
  }

  isRegionSelected(region) {
    const {
      selectedNeighborhoods,
    } = this.props

    for (var i = 0; i < region.neighbourhoods.length; ++ i) {
      if (selectedNeighborhoods.indexOf(region.neighbourhoods[i].name) < 0) {
        return false
      }
    }
    return true
  }

  clearNeighborhoods(event) {
    const {
      setSelectedNeighborhoods,
    } = this.props

    event.stopPropagation()
    setSelectedNeighborhoods([])
    this.openNeighborhoodPanel()
  }

  clearAddress() {
    this.openNeighborhoodPanel()
  }

  updateSelectedNeighborhoods(neighborhood) {
    const {
      setSelectedNeighborhoods,
      selectedNeighborhoods,
    } = this.props

    let newSelectedNeighborhoods = selectedNeighborhoods

    const neighborhoodIndex = selectedNeighborhoods.indexOf(neighborhood.name)

    if (neighborhoodIndex > -1) {
      newSelectedNeighborhoods.splice(neighborhoodIndex, 1)
    } else {
      newSelectedNeighborhoods.unshift(neighborhood.name)
      this.clearSearchbarInput()
    }

    setSelectedNeighborhoods(newSelectedNeighborhoods)
  }

  render() {
    const {
      neighborhoods,
      submitButtonText,
      highlightedNeighborhood,
      selectedNeighborhoods,
      onSubmitAddress,
      initialAddress,
    } = this.props

    const {
      showNeighborhoodPanel,
      neighborhoodQuery,
      activeSublocality,
    } = this.state

    const groupedSelectedNeighborhoods = this.getGroupedSelectedNeighborhoods()

    return (
      <div className="neighbourhood-searchbar">
        {
          showNeighborhoodPanel ?
            <div
              className="neighbourhood-searchbar-overlay"
              onClick={this.submit}
            ></div>
            : null
        }
        {
          showNeighborhoodPanel || (!selectedNeighborhoods.length && !initialAddress) ?
            <div className="neighbourhood-searchbar-input">
              <input
                type="text"
                placeholder="Neighborhood"
                autoComplete="off"
                autoCorrect="off"
                ref={input => { this.neighborhoodQueryInput = input }}
                value={neighborhoodQuery}
                onChange={(e) => { this.setState({ neighborhoodQuery: e.target.value }) }}
                onFocus={this.openNeighborhoodPanel}
                />
              <svg className="neighbourhood-searchbar-input-icon" width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <g id="Sale" transform="translate(-516.000000, -144.000000)" fill="#A5A3B8" fillRule="nonzero">
                          <g id="noun_Search_705166" transform="translate(516.000000, 144.000000)">
                              <path d="M15.9244214,14.5136202 L12.699733,11.2889319 C13.8082197,9.82774499 14.3120772,7.96347205 14.0097627,5.94804184 C13.5562909,2.87451078 11.0370031,0.455994532 7.96347205,0.0529084908 C3.37836833,-0.501334816 -0.501334816,3.37836833 0.0529084908,7.96347205 C0.455994532,11.0370031 2.87451078,13.5562909 5.94804184,14.0097627 C7.96347205,14.3120772 9.82774499,13.7578339 11.2889319,12.699733 L14.5136202,15.9244214 C14.6143917,16.0251929 14.765549,16.0251929 14.8663205,15.9244214 L15.9244214,14.8663205 C16.0251929,14.765549 16.0251929,14.6143917 15.9244214,14.5136202 Z M7.05652846,12.095104 C4.28531192,12.095104 2.01795294,9.82774499 2.01795294,7.05652846 C2.01795294,4.28531192 4.28531192,2.01795294 7.05652846,2.01795294 C9.82774499,2.01795294 12.095104,4.28531192 12.095104,7.05652846 C12.095104,9.82774499 9.82774499,12.095104 7.05652846,12.095104 Z" id="Shape"></path>
                          </g>
                      </g>
                  </g>
              </svg>
              {
                showNeighborhoodPanel && submitButtonText ?
                  <div
                    className="submit-button"
                    onClick={this.submit}>{submitButtonText}</div>
                  : null
              }
            </div>
            : null
        }
        {
          !showNeighborhoodPanel && selectedNeighborhoods.length ?
            <div className="neighbourhood-searchbar-input">
              <div
                className="neighbourhood-searchbar-input-placeholder"
                onClick={this.openNeighborhoodPanel}
                >
                { groupedSelectedNeighborhoods.length > 1 ? selectedNeighborhoods.length + ' neighborhoods' : groupedSelectedNeighborhoods[0] }
              </div>
              <i className="nc-icon-mini ui-1_bold-remove pointer"
                onClick={this.clearNeighborhoods}></i>
            </div>
            : null
        }
        {
          !showNeighborhoodPanel && initialAddress ?
            <div className="neighbourhood-searchbar-input">
              <div
                className="neighbourhood-searchbar-input-placeholder"
                onClick={this.openNeighborhoodPanel}
                >{initialAddress}</div>
              <i className="nc-icon-mini ui-1_bold-remove pointer"
                onClick={this.openNeighborhoodPanel}></i>
            </div>
            : null
        }
        {
          showNeighborhoodPanel ?
            <div className="neighbourhood-searchbar-panel">
              <div className="neighbourhood-searchbar-panel-header">
                <div className="segmented-control">
                  {
                    neighborhoods.map(sublocality => (
                      <div key={`sublocality-${sublocality.sublocality}`} className={classnames('segmented-control-option', {
                          'segmented-control-option-active': activeSublocality && activeSublocality.sublocality === sublocality.sublocality,
                        })}>
                        <label onClick={() => {
                          $('.neighbourhood-options').stop().animate({
                            scrollTop: document.querySelectorAll(`.region-wrapper[sublocalityname='${sublocality.sublocality}']`)[0].offsetTop,
                          })

                          this.setState({
                            activeSublocality: sublocality,
                          })
                        }}>
                          {sublocality.sublocality}
                        </label>
                      </div>
                    ))
                  }
                </div>
              </div>
              <div className="neighbourhood-options">
                {
                  selectedNeighborhoods.length ?
                    <div className="neighbourhood-searchbar-panel-selection">
                      {
                        selectedNeighborhoods.length ?
                          <div className="neighbourhood-searchbar-panel-selection-chips">
                            {
                              groupedSelectedNeighborhoods.map((neighborhood) => (
                                <span className="chip mr-10 mb-10 pointer"
                                  onClick={this.removeFromSelection.bind(this, neighborhood)}>
                                  {neighborhood}
                                  <i className="nc-icon-mini ui-1_simple-remove pointer"></i>
                                </span>
                              ))
                            }
                          </div>
                          : null
                      }
                    </div>
                  : null
                }
                {
                  neighborhoods.map(sublocality => (
                    <div
                      key={`sublocality-group-${sublocality.sublocality}`}
                      className="region-wrapper"
                      sublocalityname={sublocality.sublocality}
                    >
                      <div  className="region region-select-all"
                        onClick={this.toggleWholeSublocality.bind(this, sublocality)}>
                        <div className="checkbox-form-control">
                          <input
                            id={sublocality.sublocality}
                            type="checkbox"
                            onChange={this.toggleWholeSublocality.bind(this, sublocality)}
                            checked={this.isSublocalitySelected(sublocality)}
                            />
                          <label htmlFor={sublocality.sublocality}>
                            <div className="checkmark">
                              <i className="checkmark-icon nc-icon-mini ui-1_check-small"></i>
                            </div>
                            <span>Select all {sublocality.sublocality}</span>
                          </label>
                        </div>
                      </div>
                      {
                        sublocality.regions.map((region) => {
                          return this.isRegionFiltered(region) ?
                            <div key={region.id}>
                              <div
                                className={classnames('region', {
                                  'hidden-xs': !this.isNeighborhoodFiltered(region.name)
                                })}>
                                <div className="checkbox-form-control">
                                  <input
                                    id={region.name}
                                    type="checkbox"
                                    onChange={this.toggleWholeRegion.bind(this, region)}
                                    checked={this.isRegionSelected(region)}
                                    />
                                  <label htmlFor={region.name}>
                                    <div className="checkmark">
                                      <i className="checkmark-icon nc-icon-mini ui-1_check-small"></i>
                                    </div>
                                    <span>{region.name}</span>
                                  </label>
                                </div>
                              </div>
                              <div className="neighbourhood-container">
                                {
                                  region.neighbourhoods.map((neighborhood) => {
                                    return this.isNeighborhoodFiltered(neighborhood.name) ?
                                      <div
                                        key={neighborhood.id}
                                        className={classnames('neighbourhood', {
                                          highlighted: highlightedNeighborhood && (highlightedNeighborhood.name === neighborhood.name)
                                        })}>
                                        <div className="checkbox-form-control">
                                          <input
                                            id={neighborhood.name}
                                            type="checkbox"
                                            onChange={this.updateSelectedNeighborhoods.bind(this, neighborhood)}
                                            checked={selectedNeighborhoods.indexOf(neighborhood.name) > -1}
                                            />
                                          <label htmlFor={neighborhood.name}>
                                            <div className="checkmark">
                                              <i className="checkmark-icon nc-icon-mini ui-1_check-small"></i>
                                            </div>
                                            <span>{neighborhood.name}</span>
                                          </label>
                                        </div>
                                      </div>
                                      : null
                                  })
                                }
                              </div>
                            </div>
                            : null
                        })
                      }
                    </div>
                  ))
                }
              </div>
              {
                neighborhoodQuery && onSubmitAddress ?
                  <div
                    className="neighbourhood-searchbar-panel-address"
                    onClick={this.submitSearchAddress}>
                    Search address for <span className="bold">{ neighborhoodQuery }</span>...
                  </div>
                  : null
              }
            </div>
            : null
        }
      </div>
    )
  }
}
