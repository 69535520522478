/* global __CLIENT__ */

import React from 'react'
import autobind from 'autobind-decorator'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import $ from'jquery'
import qs from 'qs'
import classnames from 'classnames'
import NumberFormat from 'react-number-format'
import Helmet from 'react-helmet'
import { JsonLd } from 'react-schemaorg'
import LazyLoad from 'react-lazyload'

import * as actions from '../actions'
import { numberWithCommas } from '../helpers'

import NeighborhoodFilter from '../components/NeighborhoodFilter'
import RentalCard from '../components/RentalCard'
import SaleCard from '../components/SaleCard'
// import PricingTable from '../components/PricingTable'

// import ListingTitle from '../components/ListingTitle'
// import HeroRentImage from '../assets/images/hero-urban-2.svg'
// import HeroRentImage from '../assets/images/hero-rent.svg'
// import HeroRentImage from '../assets/images/hero-city-street.svg'
// import HeroRentSmallImage from '../assets/images/hero-urban-small.svg'
// import HeroListImage from '../assets/images/hero-list.svg'
// import HeroListSmallImage from '../assets/images/hero-list-small.svg'
// import BetterHomeSearchImage from '../assets/images/couple-browsing.svg'
// import BetterHomeSearchImageMobile from '../assets/images/better-home-search-mobile.svg'
// import BetterHomeSearchImageMobile from '../assets/images/couple-browsing-mobile.svg'

// import LaptopImage from '../assets/images/new-listing-laptop.svg'
// import LaptopMobileImage from '../assets/images/new-listing-laptop-mobile.svg'
// import PhoneImage from '../assets/images/new-listing-phone.svg'
// import HouseImage from '../assets/images/new-listing-house.svg'
// import HomeImage from '../assets/images/new-listing-home.svg'
// import HomeMobileImage from '../assets/images/new-listing-home-mobile.svg'

// import YahooFinanceLogoMono from '../assets/images/yahoo-finance-mono.png'
// import ApartmentTherapyLogoMono from '../assets/images/apartment-therapy-mono.png'
// import AlleyWatchLogoMono from '../assets/images/alleywatch-mono.png'
// import MsnMoneyLogoMono from '../assets/images/msn-money-mono.png'
// import NYPostLogoMono from '../assets/images/ny-post-mono.png'
// import PatchLogoMono from '../assets/images/patch-mono.png'
import routes from '../helpers/routes'

@connect(state => ({
  homepageRentals: state.rental.homepageListings,
  homepageSales: state.sale.homepageListings,
  selectedNeighborhoods: state.neighborhood.selectedNeighborhoods,
  neighborhoods: state.neighborhood.neighborhoods,
  zones: state.app.zones,
  user: state.user.user,
}), actions)
@autobind
export default class Home extends React.Component {
  constructor(props) {
    super(props)

    const term = props.location && props.location.pathname.slice(1)

    this.state = {
      selectedSearchType: term === 'buy' ? 'sale' : 'rental',
      selectedHeroAction: term === 'buy' ? 'sale' : 'rent',
      selectedListType: term === 'list-your-sale' ? 'sale' : 'rental',
      selectedBeds: [],
      priceMin: null,
      priceMax: null,
      activeFaq: 1,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location,
    } = this.props

    if (location.pathname !== prevProps.location.pathname) {
      const term = location && location.pathname.slice(1)

      this.setState({
        selectedSearchType: term === 'buy' ? 'sale' : 'rental',
        selectedHeroAction: term === '' ? 'rent' : (term === 'buy' ? 'sale' : 'list'),
        selectedListType: term === 'list-your-sale' ? 'sale' : 'rental',
      })
    }
  }

  searchListings() {
    const {
      history,
      selectedNeighborhoods,
    } = this.props

    const {
      selectedBeds,
      priceMin,
      priceMax,
    } = this.state

    const filters = {
      filter_by: {
        neighbourhood: selectedNeighborhoods,
        bedrooms: selectedBeds,
        price: {},
      }
    }

    if (priceMin) {
      filters.filter_by.price.min = priceMin
    }

    if (priceMax) {
      filters.filter_by.price.max = priceMax
    }

    const query = qs.stringify(filters, {
      allowDots: true,
    })

    if (this.state.selectedSearchType === 'rental') {
      history.push({
        pathname: '/for-rent/nyc',
        search: query,
      })
    } else {
      history.push({
        pathname: '/for-sale/nyc',
        search: query,
      })
    }
  }

  searchListingsAddress(address) {
    const {
      history,
    } = this.props

    const filters = {
      filter_by: {
        address: address,
      }
    }

    const query = qs.stringify(filters, {
      allowDots: true,
    })

    if (this.state.selectedSearchType === 'rental') {
      history.push({
        pathname: '/for-rent/nyc',
        search: query,
      })
    } else {
      history.push({
        pathname: '/for-sale/nyc',
        search: query,
      })
    }
  }

  onNeighbourhoodFilterOpen() {
    const panelBottomOffset = $('.neighbourhood-searchbar-panel').offset().top + $('.neighbourhood-searchbar-panel').height()
    const windowBottomOffset = ($(window).scrollTop() || $('body').scrollTop()) + $(window).height()

    setTimeout(function () {
      if ($(window).width() > 767 && panelBottomOffset > windowBottomOffset) {
        $('html, body').animate({
          scrollTop: $(window).scrollTop() + panelBottomOffset - windowBottomOffset + 10 + 'px'
        })
      }
    }, 200)
  }

  changeSelectedHeroAction(type) {
    this.setState({
      selectedHeroAction: type,
    })
  }

  changeSelectedBeds(beds) {
    const {
      selectedBeds,
    } = this.state

    const foundIndex = selectedBeds.indexOf(beds)
    let newSelectedBeds = [...selectedBeds]

    if (foundIndex !== -1) {
      newSelectedBeds.splice(foundIndex, 1)
    } else {
      newSelectedBeds.push(beds)
    }

    this.setState({
      selectedBeds: newSelectedBeds,
    })
  }

  render() {
    const {
      history,
      neighborhoods,
      homepageRentals,
      homepageSales,
      user,
      location,
    } = this.props

    const {
      // selectedSearchType,
      selectedListType,
      selectedHeroAction,
      priceMin,
      priceMax,
      selectedBeds,
      activeFaq,
    } = this.state

    // const slickFeaturedListingsConfig = {
    //   autoplay: false,
    //   draggable: true,
    //   arrows: true,
    //   slidesToShow: 3,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         arrows: false,
    //         dots: true,
    //       },
    //     },
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         arrows: false,
    //         dots: true,
    //         slidesToShow: 1,
    //       },
    //     },
    //   ],
    // }
    const isMobile = __CLIENT__ && window && window.innerWidth < 768

    return (
      <div className="page-home">
        {
          selectedHeroAction === 'rent' ? (
            <Helmet>
              <link rel="canonical" href="https://propertyclub.nyc" />

              <title>PropertyClub: NYC Apartments for Rent</title>
              <meta name="description" content="Search NYC apartments for rent, including pet-friendly, luxury, by owner, and no-fee rentals. Browse photos, floorplans, maps, and more." />

              <meta property="og:title" content="PropertyClub: NYC Apartments for Rent" />
              <meta property="og:description" content="Search NYC apartments for rent, including pet-friendly, luxury, by owner, and no-fee rentals. Browse photos, floorplans, maps, and more." />

              <meta name="twitter:title" content="PropertyClub: NYC Apartments for Rent" />
              <meta name="twitter:description" content="Search NYC apartments for rent, including pet-friendly, luxury, by owner, and no-fee rentals. Browse photos, floorplans, maps, and more." />
            </Helmet>
          ) : null
        }
        {
          selectedHeroAction === 'sale' ? (
            <Helmet>
              <link rel="canonical" href="https://propertyclub.nyc/" />
            </Helmet>
          ) : null
        }
        <JsonLd
          item={{
           "@context":"http://schema.org",
           "@type":"Organization",
           "name":"PropertyClub NYC",
           "legalName":"PropertyClub, Inc.",
           "url":"https://propertyclub.nyc/",
           "logo":"https://propertyclub.nyc/static/media/logo-black.59da0c1e.svg",
           "foundingDate":"January 2, 2018",
           "address":{
              "@type":"PostalAddress",
              "streetAddress":"25 Broadway, 9th floor",
              "addressLocality":"New York City",
              "addressRegion":"New York",
              "postalCode":"10004",
              "addressCountry":"US"
           },
           "contactPoint":{
              "@type":"ContactPoint",
              "contactType":"Customer Support",
              "email":"contact@propertyclub.nyc",
              "telephone":"212-777-2008"
           },
           "sameAs":[
              "https://www.facebook.com/PropertyClub.NYC",
              "https://www.linkedin.com/company/propertyclub-inc/",
              "https://twitter.com/PropertyClubInc"
           ]
          }}
        />
        <section className="hero-section clearfix">
          <div className="hero-section-background">
            {/*
              selectedHeroAction === 'list' ?
                <img className="hero-image" src={HeroListImage} alt="hero-image" />
                :
                <img className="hero-image" src={HeroRentImage} alt="hero-image" style={{width: '76%'}} />
                */
            }
          </div>
          <div className="hero-content">
            <h1 className="hero-headline">{selectedHeroAction === 'rent' ? 'NYC Apartments for Rent' : 'NYC Apartments for Sale'}</h1>
            {/* <div className="hero-action-selector">
              <Link
                to="/"
                className={classnames('hero-action-selector-option', {
                  'hero-action-selector-option-active': selectedHeroAction === 'sale',
                })}
              >Rent</Link>
              <Link
                to="/buy"
                className={classnames('hero-action-selector-option', {
                  'hero-action-selector-option-active': selectedHeroAction === 'sale',
                })}
              >Buy</Link>
            </div> */}
            <div className="hero-subline">
              <div className="switch-container" style={{width: '135px'}}>
                <Link
                  to="/"
                  className={classnames('switch-option', {
                    active: selectedHeroAction === 'rent',
                  })}
                >Rent</Link>
                <Link
                  to="buy"
                  className={classnames('switch-option', {
                    active: selectedHeroAction === 'sale',
                  })}
                >Buy</Link>
                <div
                  className="switch-active-panel"
                  style={selectedHeroAction === 'rent' ? {
                    left: '-10px',
                    width: '83px',
                  } : {
                    left: 'calc(100% - 68px)',
                    width: '78px',
                  }}
                ></div>
              </div>
              <h2 className="hero-subtitle">
                {
                  selectedHeroAction === 'rent'?
                  'NYC rentals'
                  : 'NYC Real Estate'
                }
              </h2>
            </div>
            {
              selectedHeroAction === 'rent' || selectedHeroAction === 'sale' ?
                <div className="hero-selected-action">
                  <div className="hero-selected-action-form">
                    <NeighborhoodFilter
                      onPanelOpen={this.onNeighbourhoodFilterOpen}
                      onPanelClose={this.props.onNeighbourhoodFilterClose}
                      submitButtonText="Apply"
                      closeOnClickOutside={true}
                      onSubmitAddress={this.searchListingsAddress}
                      />
                    <div className="price-selector">
                      <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        placeholder="$ Min"
                        className="price-selector-option"
                        onValueChange={values => {
                          this.setState({
                            priceMin: values.value
                          })
                        }}
                        value={priceMin}
                      />
                      <NumberFormat
                        thousandSeparator={true}
                        prefix={'$'}
                        placeholder="$ Max"
                        className="price-selector-option"
                        onValueChange={values => {
                          this.setState({
                            priceMax: values.value
                          })
                        }}
                        value={priceMax}
                      />
                    </div>
                    <div className="beds-selector beds-selector-lg">
                      <div
                        onClick={this.changeSelectedBeds.bind(this, 0)}
                        className={classnames('beds-selector-option', {
                          'beds-selector-label-active': selectedBeds.indexOf(0) !== -1,
                          extend: selectedBeds.indexOf(1) !== -1,
                        })}
                        >Studio</div>
                      <div
                        onClick={this.changeSelectedBeds.bind(this, 1)}
                        className={classnames('beds-selector-option', {
                          'beds-selector-label-active': selectedBeds.indexOf(1) !== -1,
                          extend: selectedBeds.indexOf(2) !== -1,
                        })}
                        >1</div>
                      <div
                        onClick={this.changeSelectedBeds.bind(this, 2)}
                        className={classnames('beds-selector-option', {
                          'beds-selector-label-active': selectedBeds.indexOf(2) !== -1,
                          extend: selectedBeds.indexOf(3) !== -1,
                        })}
                        >2</div>
                      <div
                        onClick={this.changeSelectedBeds.bind(this, 3)}
                        className={classnames('beds-selector-option', {
                          'beds-selector-label-active': selectedBeds.indexOf(3) !== -1,
                          extend: selectedBeds.indexOf(4) !== -1,
                        })}
                        >3</div>
                      <div
                        onClick={this.changeSelectedBeds.bind(this, 4)}
                        className={classnames('beds-selector-option', {
                          'beds-selector-label-active': selectedBeds.indexOf(4) !== -1,
                        })}
                        >4+</div>
                    </div>
                    <div className="hero-selected-action-form-submit">
                      {
                        selectedHeroAction === 'rent' ?
                          <button
                            className="btn btn-accent btn-round btn-lg shadow ml-5"
                            onClick={this.searchListings}>Search</button>
                          : null
                      }
                      {
                        selectedHeroAction === 'sale' ?
                          <button
                            className="btn btn-accent btn-round btn-lg shadow ml-5"
                            onClick={this.searchListings}>Search</button>
                          : null
                      }
                    </div>
                  </div>
                </div>
              : null
            }
          </div>
        </section>
    {/* <section className="promo-section">
          <div className="container">
            <div className="promo-section-title">As seen in</div>
            <div className="promo-section-list">
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={NYPostLogoMono} alt="New York Post logo" />
              </div>
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={PatchLogoMono} alt="Patch logo" />
              </div>
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={ApartmentTherapyLogoMono} alt="Apartment Therapy logo" />
              </div>
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={AlleyWatchLogoMono} alt="AlleyWatch logo" />
              </div>
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={YahooFinanceLogoMono} alt="Yahoo Finance logo" />
              </div>
              <div className="promo-section-list-item">
                <img className="promo-section-list-item-img mono" src={MsnMoneyLogoMono} alt="MSN Money logo" />
              </div>
            </div>
          </div>
        </section> */}
    {/* <section className="better-home-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-7 col-md-8">
                <img className="better-home-section-image" src={BetterHomeSearchImage} alt="Better Home Search" />
              </div>
              <div className="col-md-5 col-md-4">
                <h3 className="better-home-section-title">
                  <div className="better-home-section-title-text">A Better Home Search</div>
                  <div className="better-home-section-title-image">
                    <img src={BetterHomeSearchImageMobile} alt="Better Home Search" />
                  </div>
                </h3>
                <div className="better-home-section-list">
                  <div className="better-home-section-list-item">
                    <svg width="62px" height="53px" viewBox="0 0 62 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="home1" transform="translate(-852.000000, -1186.000000)" fillRule="nonzero">
                          <g id="Untitled-2" transform="translate(351.000000, 1048.000000)">
                            <g id="Group" transform="translate(501.000000, 138.000000)">
                              <path d="M61.2801463,7.47136564 C61.2801463,3.38546256 58.0975136,0.175110132 54.2204883,0.175110132 L7.34898828,0.175110132 C3.47196297,0.175110132 0.289330247,3.50220264 0.289330247,7.47136564 L0.289330247,33.6211454 C0.289330247,37.7070485 3.47196297,40.9174009 7.34898828,40.9174009 L13.7142537,40.9174009 L13.7142537,52.5330396 L24.9402673,40.9174009 L54.3362204,40.9174009 C58.2132457,40.9174009 61.3958784,37.5903084 61.3958784,33.6211454 L61.3958784,7.47136564 L61.2801463,7.47136564 Z" id="Shape_12_" fill="#8DC6F9"></path>
                              <path d="M51.9058463,8.8722467 L9.8372284,8.8722467 C8.67990741,8.8722467 7.69618457,9.68942731 7.69618457,10.623348 L7.69618457,10.8568282 C7.69618457,11.7907489 8.67990741,12.6079295 9.8372284,12.6079295 L51.9058463,12.6079295 C53.0631673,12.6079295 54.0468902,11.7907489 54.0468902,10.8568282 L54.0468902,10.623348 C54.0468902,9.57268722 53.1788994,8.8722467 51.9058463,8.8722467 Z" id="Shape_11_" fill="#FFFFFF"></path>
                              <path d="M51.9058463,18.8535242 L9.8372284,18.8535242 C8.67990741,18.8535242 7.69618457,19.6707048 7.69618457,20.6046256 L7.69618457,20.8381057 C7.69618457,21.7720264 8.67990741,22.589207 9.8372284,22.589207 L51.9058463,22.589207 C53.0631673,22.589207 54.0468902,21.7720264 54.0468902,20.8381057 L54.0468902,20.6046256 C54.0468902,19.6707048 53.1788994,18.8535242 51.9058463,18.8535242 Z" id="Shape_10_" fill="#FFFFFF"></path>
                              <path d="M51.9058463,28.1927313 L9.8372284,28.1927313 C8.67990741,28.1927313 7.69618457,29.0099119 7.69618457,29.9438326 L7.69618457,30.1773128 C7.69618457,31.1112335 8.67990741,31.9284141 9.8372284,31.9284141 L51.9058463,31.9284141 C53.0631673,31.9284141 54.0468902,31.1112335 54.0468902,30.1773128 L54.0468902,29.9438326 C54.0468902,29.0099119 53.1788994,28.1927313 51.9058463,28.1927313 Z" id="Shape_9_" fill="#FFFFFF"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div className="better-home-section-list-text">
                      Total transparency and direct access to landlords, listing brokers and property managers. No more middle-men.
                    </div>
                  </div>
                  <div className="better-home-section-list-item">
                    <svg width="47px" height="56px" viewBox="0 0 47 56" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="home1" transform="translate(-860.000000, -1294.000000)">
                          <g id="Group" transform="translate(860.000000, 1294.000000)">
                            <path d="M45.768,4.75 C31.648,8.212 24.259,1.806 23.261,0.856 C23.261,0.856 23.237,0.759 23.202,0.8 C23.164,0.762 23.145,0.859 23.145,0.859 C22.2,1.859 15.168,8.658 0.882,5.968 C0.882,5.968 -0.606,31.594 7.956,41.801 C17.1,52.702 24.656,55.867 24.694,55.837 C24.731,55.865 32.168,52.357 40.644,40.915 C48.577,30.209 45.768,4.75 45.768,4.75 Z" id="Path" fill="#3151B7"></path>
                            <path d="M43.762,7.436 C30.946,10.524 24.245,4.824 23.341,3.979 C23.341,3.979 23.321,3.894 23.287,3.928 C23.253,3.894 23.237,3.983 23.237,3.983 C22.379,4.874 15.995,10.929 3.031,8.541 C3.031,8.541 1.671,31.356 9.435,40.437 C17.728,50.139 24.589,53.562 24.626,53.534 C24.661,53.561 31.402,49.825 39.097,39.633 C46.296,30.101 43.762,7.436 43.762,7.436 Z" id="Path" fill="#F4F8FF"></path>
                            <g opacity="0.06" transform="translate(2.000000, 2.000000)" fill="#010101" id="Path">
                              <path d="M21.291,4.225 C21.291,4.225 21.308,4.135 21.343,4.169 C21.379,4.131 21.401,4.222 21.401,4.222 C22.329,5.087 29.099,10.845 42.015,8.019 C41.888,5.702 41.728,4.253 41.728,4.253 C28.91,7.34 22.208,1.639 21.304,0.797 C21.304,0.797 21.286,0.71 21.251,0.746 C21.216,0.713 21.201,0.798 21.201,0.798 C20.342,1.689 13.96,7.747 0.996,5.357 C0.996,5.357 0.91,6.811 0.904,9.13 C13.959,11.258 20.408,5.143 21.291,4.225 Z"></path>
                            </g>
                            <path d="M40.114,13.865 C30.434,16.238 24.199,11.644 23.517,10.994 C23.517,10.994 23.501,10.927 23.476,10.955 C23.451,10.928 23.439,10.996 23.439,10.996 C22.791,11.683 16.875,16.595 7.083,14.748 C7.083,14.748 7.155,32.069 13.025,39.064 C19.294,46.537 24.476,48.708 24.498,48.688 C24.526,48.706 29.626,46.302 35.435,38.457 C40.871,31.116 40.114,13.865 40.114,13.865 Z" id="Path" fill="#3151B7"></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div className="better-home-section-list-text">
                      Your privacy protected. We’ll never sell your personal information to third parties like Zillow and Streeteasy do.
                    </div>
                  </div>
                  <div className="better-home-section-list-item mt-10">
                    <svg width="53px" height="53px" viewBox="0 0 53 53" version="1.1" xmlns="http://www.w3.org/2000/svg">
                      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g id="home1" transform="translate(-857.000000, -1408.000000)" fillRule="nonzero">
                          <g id="Untitled-2" transform="translate(351.000000, 1048.000000)">
                            <g id="Group" transform="translate(506.000000, 360.000000)">
                              <circle id="Oval" fill="#EDAA00" cx="26.5" cy="26.5" r="26.1497797"></circle>
                              <path d="M41.2092511,16.8105727 C40.2753304,15.876652 38.7577093,15.876652 37.8237885,16.8105727 L23.5231278,31.1112335 L15.1762115,22.7643172 C14.2422907,21.8303965 12.7246696,21.8303965 11.7907489,22.7643172 C10.8568282,23.6982379 10.8568282,25.215859 11.7907489,26.1497797 L21.4801762,35.839207 C21.5385463,35.9559471 21.6552863,36.0726872 21.7720264,36.1894273 C22.2389868,36.6563877 22.8810573,36.8898678 23.5231278,36.8898678 C24.1651982,36.8898678 24.7488987,36.6563877 25.2742291,36.1894273 C25.3909692,36.0726872 25.5077093,35.9559471 25.5660793,35.839207 L41.2092511,20.1960352 C42.1431718,19.2037445 42.1431718,17.6861233 41.2092511,16.8105727 Z" id="Shape" fill="#FFFFFF"></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <div className="better-home-section-list-text">
                      Multi layer listing verification. Get the best prices on the best homes in NYC.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          <div className="search-promo">
            <h4 className="search-promo-title">Find Your Dream Home</h4>
            <Link className="btn btn-accent btn-round" to="/for-rent/nyc">Search Rentals</Link>
            <Link className="btn btn-accent2 btn-round" to="/for-sale/nyc">Search Sales</Link>
          </div>
        </div> */}
        <section className="listings-section">
          <div className="container">
            <h3 className="listings-section-title">Trending Apartments For Rent in NYC</h3>
            {
              selectedHeroAction === 'rent' ?
                <div className="listings-section-cards">
                  <h3 className="listings-section-cards-title">No Fee NYC Apartments</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageRentals.noFeeList && homepageRentals.noFeeList.length ? homepageRentals.noFeeList.map(listing => (
                        <RentalCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageRentals.noFeeTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-rent/nyc/no-fee"
                      >Search {numberWithCommas(homepageRentals.noFeeTotal)} no fee apartments</Link>
                      : null
                  }
                  <h3 className="listings-section-cards-title">Pet Friendly NYC Apartments</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageRentals.petFriendlyList && homepageRentals.petFriendlyList.length ? homepageRentals.petFriendlyList.map(listing => (
                        <RentalCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageRentals.petFriendlyTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-rent/nyc/pet-friendly"
                      >Search {numberWithCommas(homepageRentals.petFriendlyTotal)} pet friendly apartments in NYC</Link>
                      : null
                  }
                  <h3 className="listings-section-cards-title">Luxury NYC Apartments</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageRentals.luxuryList && homepageRentals.luxuryList.length ? homepageRentals.luxuryList.map(listing => (
                        <RentalCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageRentals.luxuryTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-rent/nyc/luxury"
                      >Search {numberWithCommas(homepageRentals.luxuryTotal)} luxury apartments in NYC</Link>
                      : null
                  }
                </div>
                :
                <div className="listings-section-cards">
                  <h3 className="listings-section-cards-title">NYC Condos</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageSales.condosList && homepageSales.condosList.length ? homepageSales.condosList.map(listing => (
                        <SaleCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                          location={location}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageSales.condosTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-sale/nyc"
                      >Search {numberWithCommas(homepageSales.condosTotal)} condos</Link>
                      : null
                  }
                  <h3 className="listings-section-cards-title">NYC Townhouses, Brownstones and Single-Family Houses</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageSales.townhousesList && homepageSales.townhousesList.length ? homepageSales.townhousesList.map(listing => (
                        <SaleCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                          location={location}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageSales.townhousesTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-sale/nyc"
                      >Search {numberWithCommas(homepageSales.townhousesTotal)} townhouses, brownstones and single-family houses</Link>
                      : null
                  }
                  <h3 className="listings-section-cards-title">Manhattan NYC Apartments</h3>
                  <div className="listings-section-cards-list">
                    {
                      homepageSales.manhattanList && homepageSales.manhattanList.length ? homepageSales.manhattanList.map(listing => (
                        <SaleCard
                          key={listing.id}
                          listing={listing}
                          user={user}
                          hideFavorite={true}
                          location={location}
                        />
                      )) : null
                    }
                  </div>
                  {
                    homepageSales.manhattanTotal ?
                      <Link
                        className="listings-section-cards-link"
                        to="/for-sale/manhattan"
                      >Search {numberWithCommas(homepageSales.manhattanTotal)} Manhattan apartments</Link>
                      : null
                  }
                </div>
            }
          </div>
        </section>
        <section className="neighborhood-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <h3 className="neighborhood-section-title">New York City Neighborhood<br/>Guides</h3>
                <div className="neighborhood-section-cards visible-xs">
                  {
                    neighborhoods && neighborhoods.length && [...neighborhoods].splice(0, 5).map(neighborhood => (
                      <div
                        key={neighborhood.id}
                        className="neighborhood-section-card"
                        >
                        <LazyLoad>
                          <img className="object-fit-cover" src={neighborhood.neighbourhood_photos.length && neighborhood.neighbourhood_photos[0].path} alt={neighborhood.name} />
                        </LazyLoad>
                        <div className="neighborhood-section-card-overlay"></div>
                        <h5 className="neighborhood-section-card-title">{neighborhood.name}</h5>
                      </div>
                    ))
                  }
                </div>
                <div className="neighborhood-section-description">
                  Get the inside scoop on what it’s like to live in New York City’s hottest neighborhoods before you move.
                  Explore the best of each borough by searching the <Link className="accent2 fw-500" to={routes.manhattanNeighborhoods}>best Manhattan neighborhoods</Link>, <Link className="accent2 fw-500" to={routes.brooklyNeighborhoods}>best Brooklyn neighborhoods</Link>, and <Link className="accent2 fw-500" to={routes.queensNeighborhoods}>best Queens neighborhoods
                  </Link>. Our guides include everything from pricing data to information on the neighborhood’s vibe, landmarks, and its cultural and entertainment scenes.
                </div>
              </div>
              <div className="col-sm-6 hidden-xs">
                <div className="neighborhood-section-cards">
                  {
                    neighborhoods && neighborhoods.length && [...neighborhoods].splice(0, 5).map(neighborhood => (
                      <div
                        key={neighborhood.id}
                        className="neighborhood-section-card"
                        >
                        <LazyLoad>
                          <img className="object-fit-cover" src={neighborhood.neighbourhood_photos.length && neighborhood.neighbourhood_photos[0].path} alt={neighborhood.name} />
                        </LazyLoad>
                        <div className="neighborhood-section-card-overlay"></div>
                        <h5 className="neighborhood-section-card-title">{neighborhood.name}</h5>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}
